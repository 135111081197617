import React from "react";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));
import { motion } from "framer-motion";
import milaOverlayImg from "assets/images/landingAir/mila-overlay/mila-opened-door-filter-boxes.png";
import milaOverlayWEBP from "assets/images/landingAir/mila-overlay/mila-opened-door-filter-boxes.png?as=webp";
import { useIsMobile } from "../../hooks";
/**
 * @returns {JSX.Element}
 * @constructor
 */

const MilaOverlay = () => {
  const isMobile = useIsMobile(769);

  return (
    <section className="mila-overlay">
      <div className="container">
        <header className="mila-overlay__header">
          <motion.h2
            className="mila-overlay__title"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            Of course, <br /> she’s a d🤫mn good air purifier too.
          </motion.h2>
          <div className="mila-overlay__header-desc">
            <motion.strong
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.01 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              She brings the receipts to back it up.
            </motion.strong>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.02 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              {isMobile ? (
                <>
                  Dust, dander, chemical fumes, seasonal allergens, smoke,
                  stinky odors…Mila takes no prisoners. And the best part? You
                  can see the work for yourself.
                </>
              ) : (
                <>
                  Dust, dander, chemical fumes, seasonal allergens, smoke,
                  stinky odors…Mila takes no prisoners.
                </>
              )}
            </motion.p>
          </div>
        </header>
        <ImageLoader
          className="mila-overlay__media img-relative"
          placeholderAspectRatio={580 / 680}
          image={milaOverlayImg}
          webp={milaOverlayWEBP}
          alt={"Air Purifier"}
        />
      </div>
    </section>
  );
};

export default MilaOverlay;
